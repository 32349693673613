@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-image: url('./../img/BG3\ \(1\).png');
  // background-repeat: no-repeat;
  // background-size: cover;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swal-confirm-button {
  background-color: #21af7d !important;
  width: 18rem;
}

.swal-html-cls {
  background-color: #21af7d !important;
  text-align: start;
}
